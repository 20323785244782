import { config } from "config"
import { options } from "utils"

const localidadesApi = {
  getLocalidades: async (query = "") => {
    const resp = await fetch(`${config.URL_API_PRIVATE}/localidades?${query}`, options("GET"))
    const data = await resp.json()
    return data
  },
  getAllFiliales: async (q="") => {
    const resp = await fetch(`${config.URL_API_PRIVATE}/filiales/all?${q}`, options("GET"))
    const data = await resp.json()
    return data
  },
  createLocalidad: async (body) => {
    const resp = await fetch(`${config.URL_API_PRIVATE}/localidades`, options("POST", body))
    const data = await resp.json()
    return data
  },
  updateLocalidad: async (localidadID, body) => {
    const resp = await fetch(`${config.URL_API_PRIVATE}/localidades/${localidadID}`, options("PUT", body))
    const data = await resp.json()
    return data
  },
}
export default localidadesApi