import { Layout } from 'antd';
import Loading from 'components/Loading';
import { Suspense, useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import SimpleBar from 'simplebar-react';
import CambiarClave from 'components/CambiarClave';
import Footer from './Footer';
import useAuth from 'hooks/useAuth';
import React from 'react';

const AppLayout = ({ children }) => {
  const { Content } = Layout;

  const [collapsed, setCollapsed] = useState(false);
  const { usuario, loading } = useAuth()
  if (!usuario || loading) {
    return <Loading fullscreen />
  }

  return (
    <Layout>
      {usuario.cambiarcontraseña &&
        <CambiarClave />
      }
      <Sidebar collapsed={collapsed} />
      <Layout >
        <Header collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content>
          <Suspense fallback={<Loading />}>
            <SimpleBar >
              {children}
            </SimpleBar>
          </Suspense>
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default AppLayout