import useQuery from "hooks/useQuery"
import queryString from "query-string";
import localidadesApi from "routes/localidades/localidadesApi"
import { showErrorModal } from "utils"

const useGetFiliales = (query) => {
  const { refetch, data } = useQuery({
    queryFn: localidadesApi.getAllFiliales,
    onError: err => showErrorModal({ err }),
    args: [queryString.stringify(query)]
  })

  const filiales = data?.data?.filiales;
  const filialesOptions = filiales?.map(f => ({
    value: f.id,
    label: f.nombre,
  }))
  
  return {
    filialesOptions,
    filiales,
    refetch
  };
};

export default useGetFiliales;