import { options } from "utils";
import { config } from "./config";
import { AUTH_TOKEN } from "constants/auth";

export const PUBLIC_BASE_URL = "http://localhost:9000/api/v1"

const api = {
  funciones: {
    getAll: async (q = "") => {
      const resp = await fetch(`${config.URL_API_PRIVATE}/funciones`, options("GET"))
      const data = await resp.json()
      return data
    }
  },
  personal: {
    buscar: async (q = "") => {
      const resp = await fetch(`${config.URL_API_PRIVATE}/personal/buscar?${q}`, options("GET"))
      const data = await resp.json()
      return data
    },
    get: async id => {
      const response = await fetch(`${config.URL_API_PRIVATE}/personal/${id}`, options("GET"))
      const data = await response.json()
      return data
    },
  },
  personalTipos: {
    getAll: async () => {
      const response = await fetch(config.URL_API_PRIVATE + `/personaltipos`, options("GET"))
      const data = await response.json();
      return data;
    },
  },
  vehiculosSeguros: {
    getSegurosTipos: async () => {
      const response = await fetch(config.URL_API_PRIVATE + `/vehiculossegurostipos`, options("GET"))
      const data = await response.json();
      return data;
    },
  },
  areas: {
    getAll: async () => {
      const response = await fetch(config.URL_API_PRIVATE + `/areas`, options("GET"))
      const data = await response.json();
      return data;
    }
  },
  departamentos: {
    getAll: async () => {
      const response = await fetch(config.URL_API_PRIVATE + `/departamentos`, options("GET"))
      const data = await response.json();
      return data;
    }
  },
  filiales: {
    getHijasByLocalidad: async (localidadID) => {
      const response = await fetch(config.URL_API_PRIVATE + `/filiales/localidades/${localidadID}/hijas`, options("GET"))
      const data = await response.json();
      return data;
    },
  },
  filiales_contactos: {
    getAll: async (id) => {
      const url = `${config.URL_API_PRIVATE}/filiales/${id}/contactos`
      const response = await fetch(url, options("GET"));
      const data = await response.json();
      return data;
    },
  },
  clientesDomiciliosTipos: {
    getAll: async () => {
      const response = await fetch(config.URL_API_PRIVATE + `/clientesdomiciliostipos`, options("GET"))
      const data = await response.json();
      return data;
    }
  },
  agildata: {
    getByDni: async (dni) => {
      const response = await fetch(config.URL_API_PRIVATE + `/getByDni?dni=${dni}`, options("GET"))
      const data = await response.json();
      return data;
    },
    getByCuit: async (cuit) => {
      const response = await fetch(config.URL_API_PRIVATE + `/getByCuit/${cuit}`, options("GET"))
      const data = await response.json();
      return data;
    },
  },
  frecuencias: {
    getAll: async () => {
      const response = await fetch(config.URL_API_PRIVATE + `/frecuencias`, options("GET"))
      const data = await response.json();
      return data;
    }
  },
  actividades: {
    getAll: async () => {
      const response = await fetch(config.URL_API_PRIVATE + `/actividades`, options("GET"))
      const data = await response.json();
      return data;
    }
  },
  actividades: {
    getAll: async () => {
      const response = await fetch(config.URL_API_PRIVATE + `/actividades`, options("GET"))
      const data = await response.json();
      return data;
    }
  },
  nacionalidades: {
    getAll: async () => {
      const response = await fetch(config.URL_API_PRIVATE + `/nacionalidades`, options("GET"));
      const data = await response.json();
      return data;
    },
  },
  preciosTipos: {
    async getAll() {
      const response = await fetch(`${config.URL_API_PRIVATE}/preciostipos`, options("GET"))
      const data = await response.json()
      return data
    }
  },
  contactosTipos: {
    async getAll() {
      const response = await fetch(`${config.URL_API_PRIVATE}/contactos/tipos`, options("GET"))
      const data = await response.json()
      return data
    }
  },
  articulos: {
    async getAll() {
      const response = await fetch(`${config.URL_API_PRIVATE}/articulos/all`, options("GET"))
      const data = await response.json()
      return data
    },
    async search(q = "") {
      const response = await fetch(`${config.URL_API_PRIVATE}/articulos/search?q=${q}`, options("GET"))
      const data = await response.json()
      return data
    },
    async get(articuloID) {
      const response = await fetch(`${config.URL_API_PRIVATE}/articulos/${articuloID}`, options("GET"))
      const data = await response.json()
      return data
    },
  },
  auth: {
    async authenticate() {
      const response = await fetch(config.URL_API_PRIVATE + '/authenticated', options("GET"));
      const data = await response.json();
      return data;
    },
    async getAccesosPermisos() {
      const response = await fetch(`${config.URL_API_PRIVATE}/permisos`, options("GET"))
      const data = await response.json()
      return data
    },
    async getAccesos() {
      const response = await fetch(`${config.URL_API_PRIVATE}/accesos`, options("GET"))
      const data = await response.json()
      return data
    },
    async cambiarClave(body) {
      const response = await fetch(`${config.URL_API_PRIVATE}/usuarios/cambiarclave`, options("PUT", body))
      const data = await response.json()
      return data
    },
  },
  general: {
    getCondicionesVenta: async (origen) => {
      const url = `${config.URL_API_PRIVATE}/condicionesventas/` + origen
      const response = await fetch(url, options("GET"));
      const data = await response.json();
      return data;
    },
    getUnidadesMedidas: async (q) => {
      const url = `${config.URL_API_PRIVATE}/unidadesmedidas?` + q
      const response = await fetch(url, options("GET"));
      const data = await response.json();
      return data;
    },
    getComprobantesTipos: async (q) => {
      const url = `${config.URL_API_PRIVATE}/comprobantestipos?` + q
      const response = await fetch(url, options("GET"));
      const data = await response.json();
      return data;
    },
    bajaAlta: async (body) => {
      const url = `${config.URL_API_PRIVATE}/baja/alta`
      const response = await fetch(url, options("PUT", body));
      const data = await response.json();
      return data;
    },
    getDepositos: async () => {
      const url = `${config.URL_API_PRIVATE}/depositos`
      const response = await fetch(url, options("GET"));
      const data = await response.json();
      return data;
    },
  },
  redespachantes_localidades: {
    getAll: async () => {
      const url = `${config.URL_API_PRIVATE}/redespachantes/localidades`
      const response = await fetch(url, options("GET"));
      const data = await response.json();
      return data;
    },
  },
  ariel: {
    getRubros: async (q) => {
      const url = `${config.URL_API_PRIVATE}/filiales/rubros?` + q
      const response = await fetch(url, options("GET"));
      const data = await response.json();
      return data;
    },
    getSubrubros: async (q) => {
      const url = `${config.URL_API_PRIVATE}/subrubros?` + q
      const response = await fetch(url, options("GET"));
      const data = await response.json();
      return data;
    },
    getSubrubrosBySeccion: async (secciones_id) => {
      const url = `${config.URL_API_PRIVATE}/ventas/secciones/${secciones_id}/subrubros`
      const response = await fetch(url, options("GET"));
      const data = await response.json();
      return data;
    },
    getSecciones: async () => {
      const url = `${config.URL_API_PRIVATE}/secciones`
      const response = await fetch(url, options("GET"));
      const data = await response.json();
      return data;
    },
    getCondicionesVenta: async () => {
      const url = `${config.URL_API_PRIVATE}/filiales/condventa`
      const response = await fetch(url, options("GET"));
      const data = await response.json();
      return data;
    },
    getSeccionesConPrecios: async () => {
      const url = `${config.URL_API_PRIVATE}/ventas/secciones/conprecios`
      const response = await fetch(url, options("GET"));
      const data = await response.json();
      return data;
    },
    getSubrubrosConPrecios: async (seccionesID) => {
      const url = `${config.URL_API_PRIVATE}/ventas/secciones/${seccionesID}/subrubros/conprecios`
      const response = await fetch(url, options("GET"));
      const data = await response.json();
      return data;
    },
  },
  provincias: {
    getAll: async () => {
      const response = await fetch(`${config.URL_API_PRIVATE}/provincias`, options("GET"))
      const data = await response.json()
      return data
    },
    getAtendidas: async () => {
      const response = await fetch(`${config.URL_API_PRIVATE}/provincias/atendidas`, options("GET"))
      const data = await response.json()
      return data
    },
  },
  proveedores: {
    getAll: async (q = "") => {
      const response = await fetch(`${config.URL_API_PRIVATE}/proveedores?` + q, options("GET"))
      const data = await response.json()
      return data
    },
    getAllAutocompletar: async (q = "") => {
      const response = await fetch(config.URL_API_PRIVATE + `/proveedores/autocompletar?${q}`, options("GET"))
      const data = await response.json();
      return data;
    },
    get: async (proveedorID) => {
      const response = await fetch(`${config.URL_API_PRIVATE}/proveedores/${proveedorID}`, options("GET"))
      const data = await response.json()
      return data
    },
  },
  clientes: {
    getAllAutocompletar: async q => {
      const response = await fetch(`${config.URL_API_PRIVATE}/clientes/autocompletar?${q}`, options("GET"))
      const data = await response.json()
      return data
    },
    searchDestinariosFrecuentes: async q => {
      const response = await fetch(`${config.URL_API_PRIVATE}/clientes/buscar/destinatariosfrecuentes?` + q, options("GET"))
      const data = await response.json()
      return data
    },
    get: async id => {
      const response = await fetch(`${config.URL_API_PRIVATE}/clientes/${id}`, options("GET"))
      const data = await response.json()
      return data
    },
    getContactos: async (id) => {
      const url = `${config.URL_API_PRIVATE}/clientes/${id}/contactos`
      const response = await fetch(url, options("GET"));
      const data = await response.json();
      return data;
    },
    verificarLocalidad: async (id, localidades_id) => {
      const url = `${config.URL_API_PRIVATE}/clientes/${id}/verificarlocalidad/${localidades_id}`
      const response = await fetch(url, options("GET"));
      const data = await response.json();
      return data;
    },
  },
  contratistas: {
    search: async q => {
      const response = await fetch(`${config.URL_API_PRIVATE}/contratistas/buscar?${q}`, options("GET"))
      const data = await response.json()
      return data
    },
    get: async id => {
      const response = await fetch(`${config.URL_API_PRIVATE}/contratistas/${id}`, options("GET"))
      const data = await response.json()
      return data
    },

  },
  retiros: {
    async getCount() {
      const response = await fetch(config.URL_API + '/retiros/count', options("GET"));
      const data = await response.json();
      return data;
    },
  },
  vehiculosTipos: {
    getAll: async () => {
      const response = await fetch(`${config.URL_API_PRIVATE}/vehiculostipos/all`, options("GET"))
      const data = await response.json()
      return data
    }
  },
  adjuntos: {
    async create(data) {
      const response = await fetch(config.URL_API_PRIVATE + '/adjuntos', options("POST", data))
      const responseData = await response.json();
      return responseData;
    },
    async baja(id) {
      const response = await fetch(config.URL_API_PRIVATE + '/adjuntos/baja/' + id, options("PUT"))
      const responseData = await response.json();
      return responseData;
    },
    async borrar(body) {
      const response = await fetch(config.URL_API_PRIVATE + '/adjuntos/borrar', options("PUT", body))
      const responseData = await response.json();
      return responseData;
    },
  },
  localidades: {
    get: async localidadID => {
      const response = await fetch(`${config.URL_API_PRIVATE}/localidades/${localidadID}`, options("GET"))
      const responseData = await response.json();
      return responseData;
    },
    getAll: async (q) => {
      const response = await fetch(`${config.URL_API_PRIVATE}/localidades?` + q, options("GET"))
      const data = await response.json()
      return data
    },
    buscar: async (q) => {
      const response = await fetch(`${config.URL_API_PRIVATE}/localidades/noatendidas?${q}`, options("GET"))
      const responseData = await response.json();
      return responseData;
    },
  },
  posfiscal: {
    getAll: async () => {
      const response = await fetch(`${config.URL_API_PRIVATE}/posfiscal`, options("GET"))
      const data = await response.json()
      return data
    }
  },
  vendedores: {
    getAll: async () => {
      const resp = await fetch(`${config.URL_API_PRIVATE}/vendedores`, options("GET"))
      const data = await resp.json()
      return data
    }
  },
};

export default api;

export const getCobertura = async (query = '') => {
  const url = `${config.URL_API_PRIVATE}/filiales/getcobertura?` + query
  const response = await fetch(url, options("GET"));
  const data = await response.json();
  return data;
};

export const verificarContactos = async (query = '') => {
  const url = `${config.URL_API_PRIVATE}/clientes/domicilios/contactos/props/verificar?` + query
  const response = await fetch(url, options("GET"));
  const data = await response.json();
  return data;
};

export const getAllPrecios = async (query = '') => {
  const url = `${config.URL_API_PRIVATE}/precios/all?` + query;
  const response = await fetch(url, options("GET"));
  const data = await response.json();
  return data;
};

export const getAllArticulos = async (query = '') => {
  const url = `${config.URL_API_PRIVATE}/articulos/all?` + query;
  const response = await fetch(url, options("GET"));
  const data = await response.json();
  return data;
};

export const getLocalidadesByFilialID = async (id) => {
  const url = `${config.URL_API_PRIVATE}/localidades/filial/` + id;
  const response = await fetch(url, options("GET"));
  const data = await response.json();
  return data;
};

export const getAllRegiones = async (q = '') => {
  const url = `${config.URL_API_PRIVATE}/regiones?` + q;
  const response = await fetch(url, options("GET"));
  const data = await response.json();
  return data;
};

export const getAllRegionesDemograficas = async () => {
  const url = `${config.URL_API_PRIVATE}/regiones/demograficas`;
  const response = await fetch(url, options("GET"));
  const data = await response.json();
  return data;
};

export const getAllAcuerdosTipos = async () => {
  const url = `${config.URL_API_PRIVATE}/acuerdos/tipos`;
  const response = await fetch(url, options("GET"));
  const data = await response.json();
  return data;
};

export const createAutorizacion = async (body) => {
  const response = await fetch(`${config.URL_API_PRIVATE}/autorizaciones`, options("POST", body))
  const data = await response.json()
  return data
};

export const createAutorizacionSeguimiento = async (body) => {
  const response = await fetch(`${config.URL_API_PRIVATE}/autorizaciones/estados/seguimiento`, options("POST", body))
  const data = await response.json()
  return data
};

export const getAcuerdo = async (id) => {
  const url = `${config.URL_API_PRIVATE}/acuerdos/${id}`;
  const response = await fetch(url, options("GET"));
  const data = await response.json();
  return data;
};

export const getAutorizacionesEstados = async (q = '') => {
  const url = `${config.URL_API_PRIVATE}/autorizaciones/estados?` + q;
  const response = await fetch(url, options("GET"));
  const data = await response.json();
  return data;
};

export const imprimirComprobante = async (body) => {
  const response = await fetch(`http://localhost:9003/api/print/comprobante`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'AUTHORIZATION': `Bearer ${localStorage.getItem(AUTH_TOKEN)}`
    },
    body: JSON.stringify(body),
});

return response;
};
