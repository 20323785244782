import { LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, Space, Typography } from "antd";
import useLogout from "hooks/useLogout";
import useStyles from "hooks/useStyles";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const UserMenu = () => {
  const { fontSizeLG, colorWhite, headerItemHeight } = useStyles()
  const usuario = useSelector(state => state.general.usuario);
  const nombre = usuario?.personal?.nombre;

  const { logout } = useLogout()
  const items = [
    {
      icon: <SettingOutlined />,
      label: (
        <Link to="/configuracion">
          Configuración
        </Link>
      ),
      key: '/configuracion',
    }, {
      icon: <LogoutOutlined />,
      label: "Salir",
      key: 'salir',
      onClick: () => {
        logout()
      }
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
    >
      <Button
        type="text"
        style={{
          fontSize: fontSizeLG,
          height: headerItemHeight,
          paddingLeft: 0,
        }}
      >
        <Space direction="horizontal" size="small" align="center">
          <Avatar size="default">
            {nombre[0]}
          </Avatar>
          <Typography.Text style={{ color: colorWhite }}>
            {nombre}
          </Typography.Text>
        </Space>
      </Button>
    </Dropdown>
  )
}

export default UserMenu