import { useState } from "react"
import useGetFiliales from "hooks/useGetFiliales"
import { filterOption } from "utils";
import { Drawer, Select, Tooltip, Button, message } from "antd"
import { EditOutlined } from "@ant-design/icons"
import { cambiarFilial } from "./api"

const CambiarFilial = ({ usuario }) => {
  const { filialesOptions } = useGetFiliales({ origen: 'CAMBIAR_FILIAL' });
  const [open, setOpen] = useState(false);

  const onChange = async (filialID) => {
    try {
      const response = await cambiarFilial({
        id: usuario?.id,
        filiales_id: filialID,
      });
      if (response.status === "success") {
        window.location.reload(false);
      } else {
        message.error(response.message, 7);
      }
    } catch (e) {
      message.error(e, 7);
    }
  };

  return (
    <>
      <Tooltip title="Cambiar filial" mouseLeaveDelay={0}>
        <EditOutlined onClick={() => setOpen(true)} />
      </Tooltip>
      <Drawer
        title="Cambiar filial"
        onClose={() => setOpen(false)}
        open={open}
        placement="top"
        height={140}
      >
        <Select
          value={usuario?.filiales_id}
          options={filialesOptions}
          placeholder="Seleccionar"
          filterOption={filterOption}
          onChange={onChange}
          showSearch
          style={{ width: '100%' }}
        />
      </Drawer>
    </>
  );
};


export default CambiarFilial;