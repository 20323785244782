import { AUTH_TOKEN } from "constants/auth"
import { useNavigate } from "react-router-dom"

const useLogout = () => {
  const navigate = useNavigate()
  const logout = () => {
    localStorage.removeItem(AUTH_TOKEN)
    navigate("/login")
  }
  return {
    logout
  }
}

export default useLogout