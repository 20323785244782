import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  personal: [],
  loading: false,
  total: undefined,

  nuevoOpen: false,
  editarOpen: false,
  selectedPersonal: undefined
}

export const filialesPersonalSlice = createSlice({
  name: 'filialesPersonal',
  initialState,
  reducers: {
    setPersonal: (state, { payload }) => {
      state.personal = payload
    },
    setTotal: (state, { payload }) => {
      state.total = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    openNuevo: (state) => {
      state.nuevoOpen = true
    },
    closeNuevo: (state) => {
      state.nuevoOpen = false
    },
    openEditar: (state, { payload }) => {
      state.editarOpen = true
      state.selectedPersonal = payload
    },
    closeEditar: (state) => {
      state.editarOpen = false
      state.selectedPersonal = undefined
    },
  },
})

export const filialesPersonalActions = filialesPersonalSlice.actions

export default filialesPersonalSlice.reducer